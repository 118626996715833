<template>
  <h1>Test iad iframe</h1>
  <iframe
    src="https://widget.pretto.fr/monthly-payments/?popretyValue=300000&contributionValue=80000&durationValue=5&font=Montserrat&partner=iad"
    frameborder="0"
  ></iframe>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
iframe {
  height: 800px;
}
</style>
